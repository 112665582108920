<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav
          class="
            navbar navbar-expand-md navbar-dark
            px-0
            pr-sm-3
            navbar-offcanvas
          "
        >
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Pengaturan</span>
          </span>
          <Menu>
            <ul class="navbar-nav w-100 pl-3 pl-md-0">
              <li class="nav-item mt-1 mb-n1">
                <a
                  href="./jual-lisensi.html"
                  class="nav-link bg-yellowish text-main"
                  title="Lisensi (Free trial 5 hari lagi)"
                  >Lisensi<span class="d-md-none">
                    (Free trial 5 hari lagi)</span
                  ></a
                >
              </li>
              <li class="nav-item mt-1 mb-n1 active">
                <a
                  href="./jual-pengaturan.html"
                  class="nav-link"
                  title="Pengaturan umum"
                  >Pengaturan Umum</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a
                  href="./jual-pengaturan-admin.html"
                  class="nav-link"
                  title="Admin Toko"
                  >Admin Toko</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a href="" @click.prevent="logout" class="nav-link" title="Logout"
                  >Logout</a
                >
              </li>
            </ul>
          </Menu>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div class="row mt-4 mb-5">
            <div class="col-12">
              <Notif />
              <Form
                @submit="savePengaturan"
                :validation-schema="schema"
                v-slot="{ errors }"
                method="POST"
                class="needs-validation"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nama"
                        >Nama Toko: <span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.nama }"
                        id="nama"
                        placeholder="Masukkan nama toko..."
                        name="nama"
                        v-model="state.toko.nama"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.nama }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <label for="foto"
                      >Banner Toko:
                      </label
                    >
                    <div class="custom-file mb-3">
                      <input
                      type="file"
                      ref="fileInput"
                      class="custom-file-input form-control"
                      id="upload"
                      accept="image/*"
                      name="foto"
                      @input="pickFile"
                      @change="onFileChange"
                    />
                      <label
                        class="custom-file-label"
                        for="upload"
                        id="upload-label"
                        >Pilih gambar...</label
                      >
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">Isian ini wajib diisi.</div>
                    </div>
                    <div class="image-area mb-4 w-50 mx-auto p-3">
                      <img
                      v-if="state.url" :src="state.url"
                      alt=""
                      class="img-fluid rounded shadow-sm mx-auto d-block"
                      />
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="invText">Teks Footer Invoice:</label>
                      <Field type="hidden" v-model="state.toko.footer" name="footer" />
                      <textarea
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.footer }"
                        rows="5"
                        id="footer"
                        name="footer"
                        v-model="state.toko.footer"
                        placeholder="Masukkan teks footer invoice..."
                      ></textarea
                      >
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.footer }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="sel1">Detail Produk di Invoice:<span class="text-danger ml-1">*</span></label>
                    <Field
                        name="status"
                        as="select"
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.detail_invoice }"
                        v-model="state.toko.detail_invoice"
                      >
                      <option :value="0">Hanya Rekap Total</option>
                      <option :value="1">Munculkan Daftar Detail Produk</option>
                    </Field>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.detail_invoice }}</div>
                  </div>
                </div> <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel1"
                        >Ukuran Teks:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control form-control-sm"
                        id="sel1"
                        disabled
                      >
                        <option value="1" selected>Default</option>
                        <option value="2">Perbesar</option>
                      </select>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">Pilih salah satu.</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel2"
                        >Default Produk/Layanan:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control form-control-sm"
                        id="sel2"
                        disabled
                      >
                        <option value="1" selected>Produk</option>
                        <option value="2">Layanan</option>
                      </select>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">Pilih salah satu.</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->

                <hr class="no-side-margin" />

                <div class="row mt-3">
                  <div class="col text-center">
                    <button
                      type="submit"
                      class="btn btn-main"
                      title="Simpan"
                      :disabled="state.loading"
                    >
                      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                      <i class="fa fa-save" v-else></i> Simpan
                    </button>
                    <button
                      type="reset"
                      class="btn btn-light ml-1"
                      title="Reset isian"
                    >
                      <i class="fas fa-sync-alt"></i> Reset
                    </button>
                    <a
                      class="btn btn-light ml-1"
                      @click="batal"
                      title="Batal"
                      ><i class="fas fa-times"></i> Batal</a
                    >
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->
              </Form>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import Menu from "../../../components/menu/Menu.vue";
import { reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { Form, Field } from 'vee-validate';
import axios from 'axios';
import { useVueSweetAlert2 } from '../../../useVueSweetAlert2.js';
import * as Yup from 'yup';
import ImageDefault from '../../../assets/img/ca2.jpg';
import Notif from '../../../helpers/Notif.vue';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      exist: false,
      toko: {
        id: '',
        nama: 'Nama Toko',
        baner: '',
        footer: "Transfer ke: \nBCA # 1234567890 a.n. Lorem Ipsum \ninfo transfer ke +62-812-3456-7890 \nTerima kasih ya ;)",
        detail_invoice: 0,
      },
      loading: false,
      url: ImageDefault
    });
    const logout = () => {
      store.dispatch("auth/logout");
    };
    const loadData = async() => {
        await axios.get('/api/seller/pengaturan').then((res) => {
            if(res.data.success) {
                if(res.data.exist){
                  state.toko.id = res.data.toko.id;
                  state.toko.nama = res.data.toko.nama;
                  state.toko.footer = res.data.toko.footer;
                  if(res.data.toko.baner){
                    state.url = process.env.VUE_APP_API_URL + res.data.toko.baner;
                  }
                  state.toko.detail_invoice = res.data.toko.detail_invoice;
                }
            }
        });
    }
    onMounted(() => {
        loadData();
        store.dispatch("error/setDefault");
    })
    const batal = () => {
        $swal.fire({
            text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                router.push({path: '/jual/pesanan-group'});
            }
        })
    }
    //start validate
    const schema = Yup.object().shape({
      nama: Yup.string().required("Nama tidak boleh kosong"),
      footer: Yup.string().required("Nama tidak boleh kosong"),
    });
    const savePengaturan = () => {
      state.loading = true;
      axios.post('/api/seller/pengaturan', state.toko).then((res) => {
        if(res.data.success){
          $swal.fire({
            icon: "success",
            title: "Selamat",
            text: "Data Anda Telah Di Perbarui!",
          });
          router.push({ path: "/jual/pesanan-group" });
        }
      }, () => {
        state.loading = false;
      })
    }
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      state,
      logout,
      batal,
      schema,
      savePengaturan,
      back,
    };
  },
  components: {
    Menu,
    Field,
    Form,
    Notif,
  },
  methods: {
    pickFile () {
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.state.toko.baner = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.state.url = URL.createObjectURL(file);
    }
  },
  computed: {
    isPhoto(){
      return this.state.toko.baner === '';
    }
  }
};
</script>